import React, { useEffect, useState } from "react"
import { Button, Container, Form, Nav, Tab } from "react-bootstrap"

import { Link } from "@StarberryUtils";
import HTMLReactParser from 'html-react-parser';
import bgCurve from "../../images/home/pg-logo-curve.png"

import "./ThankyouBlock.scss"

const ThanksyouBlock = (props) => {


  return (
    <div className="bottom-block">
      <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
      <Container>
        {
          props.Alias === "pre-qualified-confirmation" && 
          <Link to={'/pre-qualified'}  className="back-link">
            {/* <i className="right-arrow"></i>Back to Property Listing */}
            <i className="right-arrow"></i>Go Back
          </Link>
        }
        

         <div className="animated">
          {
            props.Alias === "valuation-confirmation" &&
            <span to="#" className="thank-you-head">
              {props.Pagename}
            </span>
          }
          <div className="tab-details">
              <h2 className="tab-title">
                {props.Modules.Title}
              </h2>
              <p className="tab-desc">
                {props.Modules.Content}
              </p>
              {
                props.Modules.CTA_1_Label && <Link to={props.find_prop_url ? props.find_prop_url : props.Modules.CTA_1_URL} className="btn btn-primary"><span>{props.Modules.CTA_1_Label}</span></Link>
              }
              
          </div>
         </div>
        {/*  </div> */}
      </Container>
    </div>
  )
}

export default ThanksyouBlock
