import { Link } from "@StarberryUtils";
import React from "react"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"
import QuestionIcon from "../../../images/question-icon.svg"
import "./PropertyToolkit.scss"


import {ImageModule} from "../../../modules/Image_Module";

const PropertyToolkit = props => {
  return (
    <div className="property-toolkit">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="property-toolkit-title">
               <div className="animated">
                <h2>{props.Toolkit.Title}</h2>
                <p className="d-none d-md-block d-lg-none">
                  {props.Toolkit.Content}
                </p>
                <p className="d-md-none d-lg-block">
                  {props.Toolkit.Content}
                </p>
               </div>
            </div>
             <div className="animated">
              <div className="property-toolkit-box">
                {
                  props.Toolkit.Snippet_Box && props.Toolkit.Snippet_Box.length > 0 && props.Toolkit.Snippet_Box.map((item, index) => {
                    return(
                      <div className="property-toolkit-item">
                        <div className="question-icon">
                          <ImageModule ImageSrc={item.Icon}/>
                        </div>
                        <div className="property-toolkit-info">
                          <h3>{item.Title}</h3>
                          <p>
                            {item.Content}
                          </p>
                          {
                            item.CTA_1_Label === "View Document" ? 
                              <Link to={item.CTA_1_URL} className="tracking-btn" target="_blank">{item.CTA_1_Label}</Link>
                            : <Link to={item.CTA_1_URL} className="tracking-btn">{item.CTA_1_Label}</Link>
                          }
                          
                        </div>
                      </div>
                    )
                  }) 
                }
                

              </div>
             </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PropertyToolkit
